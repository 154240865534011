import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './PGAAtlas.css';

export default class PGAAtlas extends Component {
  render() {
    return (
      <div id="page">
        <HeadAtlas />
        <HeaderAtlas />
        <section id="content" role="main">
          <Router>
            <Switch>
              <Route path={'/atlas/'} exact={true} component={BodyAtlas} />
            </Switch>
          </Router>
          <FooterAtlas />
        </section>
      </div>
    );
  }
}

class HeadAtlas extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <link rel="icon" href="/favicon.png" sizes="32x32" type="image/png" />

          <title>PGAConsult's - Atlassian Marketplace Portfolio</title>
          <meta
            name="description"
            content="Software advisory company. Develop professional software prototypes at proper costs. Ideal for startups"
          />
          <meta
            name="keywords"
            content="Software, Agile, SCRUM, Prototype, MVP, Efficient, Cheap, Fast"
          />
          <meta name="author" content="PGAConsult S.R.L." />
          <meta name="language" content="EN" />
          <meta property="og:type" content="business.business" />
          <meta
            property="business:contact_data:street_address"
            content="Street Gheorghe Dima 11/10"
          />
          <meta
            property="business:contact_data:locality"
            content="Cluj-Napoca"
          />
          <meta property="business:contact_data:postal_code" content="400358" />
          <meta
            property="business:contact_data:country_name"
            content="Romania"
          />
          <meta
            property="business:contact_data:phone_number"
            content="+40 726 711 529"
          />
          <meta
            property="og:title"
            content="PGAConsult - Atlassian Advisory Services"
          />
          <meta property="og:email" content="contact@pgaconsult.ro" />
          <meta property="og:phone_number" content="+40-726-711-529" />
        </Helmet>
      </Fragment>
    );
  }
}

class HeaderAtlas extends Component {
  render() {
    return (
      <header id="header" role="banner">
        <nav
          className="aui-header aui-dropdown2-trigger-group"
          role="navigation"
        >
          <div className="aui-header-inner">
            <div className="aui-header-primary">
              <h1
                id="logo"
                className="aui-header-logo aui-header-logo-textonly"
              >
                <a
                  href="#pga-options"
                  className="aui-dropdown2-trigger"
                  aria-controls="pga-options"
                >
                  <span className="aui-header-logo-device">PGAConsult</span>
                </a>
              </h1>
              <div id="pga-options" className="aui-style-default aui-dropdown2">
                <ul className="aui-list-truncate">
                  <li>
                    <a href="/atlas">Atlassian Extensions</a>
                  </li>
                  <li>
                    <a href="/">Website</a>
                  </li>
                  <li>
                    <a
                      href="https://marketplace.atlassian.com/vendors/1216486/pgaconsult"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vendor Profile
                    </a>
                  </li>
                  <li>
                    <a href="/atlas/sla">Service Level Agreement</a>
                  </li>
                </ul>
              </div>
              <ul className="aui-nav">
                <li>
                  <a
                    href="#epdft"
                    className="aui-dropdown2-trigger"
                    aria-controls="epdft"
                  >
                    {' '}
                    Easy PDF Tuner
                  </a>
                </li>
                <li>
                  <a href="/terms">Terms and conditions</a>
                </li>
                <li>
                  <a href="/#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

class BodyAtlas extends Component {
  render() {
    return (
      <Fragment>
        <div className="aui-page-panel-inner">
          <section className="aui-page-panel-content">
            <h1>Applications & configurations for Atlassian products</h1>
            <h2>About</h2>
            <p>
              More than 10 years of experience in customizing and implementing
              extensions for Atlassian products. It all started back in 2008
              with an Jira extension and did not stopped since.
            </p>
            <p>
              Vast experience in developing extensions scoped for proprietary
              use in individual Jira and Confluence instances.
            </p>
            <p>
              Flexible on your special requirements, Experience in extending
              both Cloud and Server editions of Atlassian's Jira and Confluence.
            </p>

            <p>Deliver solutions.</p>
            <h2>Marketplace available Products</h2>
            <p>PDF Customization toolbox</p>
            <p>
              Simple tweeks for improving the looks of your Confluence Cloud PDF
              exported pages.
            </p>
            <p>Give it a try</p>
            <AtlasArticle
              title="Custom solution - how does it work"
              intro="There are moments when even the most customizable tools require to be customized in deeper forms"
            >
              <h3>Why?</h3>
              <ul>
                <li>
                  Extend the functionalities offered by some off the shelf Jira
                  Plugins
                </li>
                <li>No external access.</li>
                <li>Limitted tooling.</li>
              </ul>
              <h4>Creational Flow</h4>
              <p>
                You decide which steps do you need and your level of
                involvement.{' '}
              </p>
              <h3>Just tell us what you need</h3>
              <p>
                The following details are important for a proper assessment of
                your need.
              </p>

              <ul className="aui">
                <li>Product: Confluence or Jira</li>
                <li>Which version do you use: Clour or Server</li>
                <li>
                  Contact person. (Just in the case you would like to transfer
                  all decisions to somebody else from your team.){' '}
                </li>
                <li>
                  Would you like to interconnect the functionality with some
                  other extensions you are using. (We we'll check if this is
                  allowed from a legal point of view - some extensions are not
                  allowed to be furhterly extended.){' '}
                </li>
                <li>
                  Current license type. (For how many user is your instance
                  licensed)
                </li>
                <li>
                  Available Budget. (If you would like to stay in a given
                  budget)
                </li>
              </ul>
              <p>Delivered artifacts.</p>
            </AtlasArticle>
          </section>
        </div>
      </Fragment>
    );
  }
}

class AtlasArticle extends Component {
  render() {
    return (
      <div className="atlas-article">
        <div className="section">
          <div className="">
            <div className="aui-page-header-image">
              <span className="aui-avatar aui-avatar-project aui-avatar-xxxlarge">
                <span className="aui-avatar-inner">
                  <img src={this.props.image} alt="" />
                </span>
              </span>
            </div>
            <div className="aui-page-header-main">
              <h2>{this.props.title}</h2>
              <p>{this.props.intro}</p>
            </div>
          </div>
        </div>
        <div className="section">{this.props.children}</div>
      </div>
    );
  }
}

class FooterAtlas extends Component {
  render() {
    return (
      <div id="footer" role="contentinfo">
        <section className="footer-body">
          <ul>
            <li>
              <a href="/">PGAConsult 2021</a>
            </li>
            <li>
              <a href="/terms" target="_blank">
                Terms of Use
              </a>
            </li>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}
