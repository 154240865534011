import React, { Component } from 'react';
import PGANavbar from '../component/nav/PGANavbar';
import { Link } from 'react-router-dom';
import { FooterSection } from './section/FooterSection';
import { MVPFactorySection } from './section/MVPFactorySection';

export default class PGAFactory extends Component {
  render() {
    return (
      <div>
        <PGANavbar switchPage={true} />
        <HeaderFactory />
        <MVPFactorySection />

        <FooterSection />
      </div>
    );
  }
}

class HeaderFactory extends Component {
  render() {
    return (
      <header className="masthead">
        <div className="container">
          <div className="intro-text text-uppercase">
            <div className=" intro-lead-in">
              <span className="hidden-xs">M.V.P. Factory</span>
            </div>
            <div className="intro-heading-2">
              <span className="hidden-xs">You came too early</span>
            </div>
            <ScrollToPage
              to={''}
              label={'Take Me Back'}
              onClick={this._onToggleNav}
            />
          </div>
        </div>
      </header>
    );
  }
}

class ScrollToPage extends Component {
  render() {
    return (
      <Link
        className={
          'btn btn-primary btn-xl text-uppercase js-scroll-trigger text-dark'
        }
        spy={true}
        smooth={true}
        duration={500}
        activeClass="active"
        to={this.props.to}
        onClick={this.props.onClick}
      >
        {this.props.label}
      </Link>
    );
  }
}
