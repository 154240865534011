import React, { Component } from 'react';
import './PGANavbar.css';
import $ from 'jquery';
import { Link } from 'react-scroll';

export default class PGANavbar extends Component {
  state = { navCollapsed: false };
  _onToggleNav = () => {
    this.setState({ navCollapsed: !this.state.navCollapsed });
  };

  navbarCollapse = function() {
    if ($('#mainNav').offset().top > 100) {
      $('#mainNav').addClass('navbar-shrink');
    } else {
      $('#mainNav').removeClass('navbar-shrink');
    }
  };

  componentDidMount() {
    this.navbarCollapse();
    $(window).scroll(this.navbarCollapse);
  }

  componentWillUnmount() {
    $(window).off('scroll', this.navbarCollapse);
  }

  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div className="container">
          {this.props.switchPage ? (
            <a href={'./'} className={'navbar-brand'}>
              PGA
            </a>
          ) : (
            <Link
              to="page-top"
              className={'navbar-brand'}
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              onClick={this.props.onClick}
            >
              PGA
            </Link>
          )}
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this._onToggleNav}
          >
            <i className="fa fa-bars" />
          </button>
          <div
            className={
              'collapse navbar-collapse  ' +
              (this.state.navCollapsed ? 'show' : '')
            }
            id="navbarResponsive"
          >
            {this.props.switchPage ? (
              <ul className="navbar-nav text-uppercase ml-auto">
                <SwitchPageLink
                  to={'about'}
                  label={'About'}
                  onClick={this._onToggleNav}
                />
                <SwitchPageLink
                  to={'services'}
                  label={'Services'}
                  onClick={this._onToggleNav}
                />
                <SwitchPageLink
                  to={'mvpfactory'}
                  label={'MVP Factory'}
                  onClick={this._onToggleNav}
                />
                <SwitchPageLink
                  to={'contact'}
                  label={'Contact'}
                  onClick={this._onToggleNav}
                />
              </ul>
            ) : (
              <ul
                className="navbar-nav text-uppercase ml-auto"
                onClick={this._onToggleNav}
              >
                <ScrollToLink
                  to={'about'}
                  label={'About'}
                  onClick={this._onToggleNav}
                />
                <ScrollToLink
                  to={'services'}
                  label={'Services'}
                  onClick={this._onToggleNav}
                />
                <ScrollToLink
                  to={'mvpfactory'}
                  label={'MVP Factory'}
                  onClick={this._onToggleNav}
                />
                <ScrollToLink
                  to={'contact'}
                  label={'Contact'}
                  onClick={this._onToggleNav}
                />
              </ul>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

class ScrollToLink extends Component {
  render() {
    return (
      <li className="nav-item">
        <Link
          className={'nav-link'}
          spy={true}
          smooth={true}
          duration={500}
          activeClass="active"
          to={this.props.to}
          onClick={this.props.onClick}
        >
          {this.props.label}
        </Link>
      </li>
    );
  }
}

class SwitchPageLink extends Component {
  render() {
    return (
      <li className="nav-item">
        <a href={'./#' + this.props.to} className={'nav-link'}>
          {this.props.label}
        </a>
      </li>
    );
  }
}
