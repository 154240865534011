import React, { Component } from 'react';

export class FooterSection extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <span className="copyright text-primary">PGAConsult 2022</span>
            </div>
            <div className="col-md-4" />
            <div className="col-md-4">
              <ul className="list-inline quicklinks">
                <li className="list-inline-item">
                  <a href="/privacypolicy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="/terms" target="_blank">
                    Terms of Use
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
