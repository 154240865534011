import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PGAHome from './page/PGAHome';
import PGASolutionDesign from './page/PGASolutionDesign';
import PGAFactory from './page/PGAFactory';
import PGAAtlas from './page/PGAAtlas';
import NoMatch from './page/NoMatch';
import { HeadDefault } from './page/section/PGAHeadDefault';

const routes = [
  {
    title: 'PGACONSULT',
    path: '/',
    component: PGAHome,
    exact: true
  },
  {
    title: 'Solution Design',
    path: '/design',
    component: PGASolutionDesign
  },
  {
    title: 'Product Factory',
    path: '/factory',
    component: PGAFactory
  },
  {
    title: 'Atlassian Extension',
    path: '/atlas',
    component: PGAAtlas
  }
];

export default class App extends Component {
  render() {
    return (
      <div>
        <HeadDefault />
        <Router>
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
            <Route component={NoMatch} />
          </Switch>
        </Router>
      </div>
    );
  }
}
