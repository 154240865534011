import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

export class HeadDefault extends Component {
  render() {
    return (
      <Fragment>
        <OpenGraph />
        <Business />
        <General />
        <Links />
      </Fragment>
    );
  }
}

class OpenGraph extends Component {
  render() {
    return (
      <Helmet>
        <meta property="og:email" content="contact@pgaconsult.ro" />
        <meta property="og:phone_number" content="+40-725-657-368" />
        <meta property="og:image" content="/img/logo-square-large.png" />
        <meta property="og:image:width" content="287" />
        <meta property="og:image:height" content="287" />
        <meta
          property="og:image:alt"
          content="Develop new solutions or extend existing ones. Containerization migration."
        />
        <meta property="og:title" content="PGA - Atlassian Advisory Services" />
        <meta property="og:type" content="business.business" />
      </Helmet>
    );
  }
}

class Business extends Component {
  render() {
    return (
      <Helmet>
        <meta
          property="business:contact_data:street_address"
          content="Street Gheorghe Dima 11/10"
        />
        <meta property="business:contact_data:locality" content="Cluj-Napoca" />
        <meta property="business:contact_data:postal_code" content="400358" />
        <meta property="business:contact_data:country_name" content="Romania" />
        <meta
          property="business:contact_data:phone_number"
          content="+40 725 657 368"
        />
      </Helmet>
    );
  }
}

class Links extends Component {
  render() {
    return (
      <Helmet>
        <link rel="icon" href="favicon.png" sizes="32x32" type="image/png" />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossorigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
          integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700"
          rel="stylesheet"
          type="text/css"
        />
        <link href="css/agency.css" rel="stylesheet" />
      </Helmet>
    );
  }
}

class General extends Component {
  render() {
    return (
      <Helmet>
        <title>PGA - Atlassian Advisory Services</title>
        <meta name="theme-color" content="#212529" />
        <meta
          name="description"
          content="Software advisory company. Develop professional software prototypes or extend existing solutions at the highest standards. Migrate existing solutions to container based deployments."
        />
        <meta
          name="keywords"
          content="Atlassian, Software, MVP, Efficient, Professional, Cloud, Orchestration"
        />
        <meta name="author" content="PGAConsult S.R.L." />
        <meta name="language" content="EN" />
        <meta name="Classification" content="Business" />
      </Helmet>
    );
  }
}
