import React, { Component } from 'react';
import { Link } from 'react-scroll';

export class HeaderSection extends Component {
  render() {
    return (
      <header className="masthead">
        <div className="container">
          <div className="intro-text">
            <div className=" intro-lead-in">
              <span className="hidden-xs">Welcome to PGA</span>
            </div>
            <div className="intro-heading-2">
              <span className="hidden-xs">Atlassian Advisory Services</span>
            </div>
            <ScrollToPage
              to={'services'}
              label={'Tell Me More'}
              onClick={this._onToggleNav}
            />
          </div>
        </div>
      </header>
    );
  }
}

class ScrollToPage extends Component {
  render() {
    return (
      <Link
        className={
          'btn btn-primary btn-xl text-uppercase js-scroll-trigger text-dark'
        }
        spy={true}
        smooth={true}
        duration={500}
        activeClass="active"
        to={this.props.to}
        onClick={this.props.onClick}
      >
        {this.props.label}
      </Link>
    );
  }
}
