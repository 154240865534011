import React, { Component } from 'react';

export class MVPFactorySection extends Component {
  render() {
    return (
      <section id="mvpfactory">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">M.V.P. Factory</h2>
              <h3 className="section-subheading text-muted">
                When dreams get a material form.
                <br /> This is how it works.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/mvpfactory/1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Envisioning</h4>
                      <h5 className="subheading">When it all started</h5>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        You stop dreaming about having a product and start
                        acting. You clear your mind and sketch down all your
                        ideas no matter how they crazy might sound like.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/mvpfactory/2.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Solution design</h4>
                      <h5 className="subheading">
                        When we embrace your vision
                      </h5>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        We help you refine your ideas and sketches into
                        technical specifications. We perform all the
                        preparations for the technical solution in a fully
                        transparent way.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/mvpfactory/5.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Coding</h4>
                      <h5 className="subheading">When a product is born</h5>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        We fire up the forge and create the solution. We take
                        care of all technical parts so you can prepare for the
                        next phase.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/mvpfactory/3.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Calibration</h4>
                      <h5 className="subheading">When we polish the result</h5>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        We go together over all the parts and apply touches that
                        will make them just as you wanted.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <a
                    className="text-uppercase js-scroll-trigger"
                    href="https://pga.atlassian.net/servicedesk/customer/portal/3/group/10"
                  >
                    <div className="timeline-image">
                      <h4 className="d-none d-md-block">
                        Start
                        <br />
                        this
                        <br />
                        chapter!
                      </h4>
                      <h2 className="d-md-none" style={{ paddingTop: '15px' }}>
                        ?
                      </h2>
                    </div>
                  </a>
                  <div className="timeline-panel d-md-none">
                    <div className="timeline-heading">
                      <h4>Start</h4>
                      <h4 className="subheading">this</h4>
                      <h4 className="subheading">chapter!</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
