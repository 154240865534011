import React, { Component } from 'react';
import PGAPage from './PGAPage';
import PGANavbar from '../component/nav/PGANavbar';
import { Link } from 'react-router-dom';
import { FooterSection } from './section/FooterSection';

export default class PGASolutionDesign extends PGAPage {
  render() {
    return (
      <div>
        <PGANavbar switchPage={true} />
        <HeaderSolutiondesing />
        <FooterSection />
      </div>
    );
  }
}

class HeaderSolutiondesing extends Component {
  render() {
    return (
      <header className="masthead">
        <div className="container">
          <div className="intro-text text-danger text-uppercase">
            <div className=" intro-lead-in">
              <span className="hidden-xs">Solution design</span>
            </div>
            <div className="intro-heading-2">
              <span className="hidden-xs">You came too early</span>
            </div>
            <ScrollToPage
              to={''}
              label={'Take Me Back'}
              onClick={this._onToggleNav}
            />
          </div>
        </div>
      </header>
    );
  }
}

class ScrollToPage extends Component {
  render() {
    return (
      <Link
        className={
          'btn btn-danger btn-xl text-uppercase js-scroll-trigger text-dark'
        }
        spy={true}
        smooth={true}
        duration={500}
        activeClass="active"
        to={this.props.to}
        onClick={this.props.onClick}
      >
        {this.props.label}
      </Link>
    );
  }
}
