import React from 'react';
import Helmet from 'react-helmet';
import PGAPage from './PGAPage';

export default class NoMatch extends PGAPage {
  render() {
    return (
      <div>
        <h1>Failed to work!</h1>
        <Helmet title="404" />
      </div>
    );
  }
}
