import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import { Link as ScrollLink } from 'react-scroll';
import './ServicesSection.css';

export class ServicesSection extends Component {
  render() {
    return (
      <section id="services" className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Services</h2>
              <h3 className="section-subheading text-muted">
                A brief selection of our main services.
                <br />
                Ping us and we'll get back with details.
              </h3>
            </div>
          </div>
          <div className="row text-center services-preview">
            <div className="col-md-4 service-preview">
              <a
                href="https://pga.atlassian.net/servicedesk/customer/portal/3/group/10"
                title="Submit first details"
              >
                <span className="fa-stack fa-4x">
                  <i className="fa fa-circle fa-stack-2x text-danger" />
                  <i className="fas fa-drafting-compass fa-stack-1x fa-inverse" />
                </span>
              </a>
              <h4 className="service-heading">Solution Design</h4>
              <p className="text-muted">
                Right things are needed from the early days.
              </p>
              <p>
                <a
                  href="https://pga.atlassian.net/servicedesk/customer/portal/3/group/10"
                  className="btn btn-danger"
                >
                  Submit first details
                </a>
              </p>
              <Link
                className="btn btn-danger text-uppercase js-scroll-trigger read-more"
                to="/design#page-top"
              >
                More
              </Link>
            </div>
            <div className="col-md-4 service-preview">
              <a
                href="https://pga.atlassian.net/servicedesk/customer/portal/3/group/10"
                title="Start discussion"
              >
                <span className="fa-stack fa-4x">
                  <i className="fa fa-circle fa-stack-2x text-primary" />
                  <i className="fa fa-industry fa-stack-1x fa-inverse" />
                </span>
              </a>
              <h4 className="service-heading">M.V.P. Factory</h4>
              <p className="text-muted">
                Right minimum viable product is needed.{' '}
              </p>
              <p>
                <a
                  href="https://pga.atlassian.net/servicedesk/customer/portal/3/group/10"
                  className="btn btn-primary"
                >
                  Fire the forge
                </a>
              </p>
              <ScrollLink
                className="btn btn-primary text-uppercase js-scroll-trigger read-more"
                to="mvpfactory"
                spy={true}
                smooth={true}
                duration={500}
              >
                More
              </ScrollLink>
            </div>
            <div className="col-md-4 service-preview">
              <a
                href="https://marketplace.atlassian.com/vendors/1216486/pgaconsult"
                title="Marketplace"
              >
                <span className="fa-stack fa-4x">
                  <i className="fa fa-circle fa-stack-2x text-success" />
                  <i className="fab fa-atlassian fa-stack-1x fa-inverse" />
                </span>
              </a>
              <h4 className="service-heading">Atlassian Extensions</h4>
              <p className="text-muted">
                Customize or extend with new functionality.
              </p>
              <p>
                <a
                  href="https://marketplace.atlassian.com/vendors/1216486/pgaconsult"
                  className="btn btn-success"
                >
                  Go to Marketplace
                </a>
              </p>
              <Link
                className="btn btn-success text-uppercase js-scroll-trigger read-more"
                to="/atlas#page-top"
              >
                More
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
