import React, { Component } from 'react';

export class TopSection extends Component {
  render() {
    return (
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">About</h2>
              <h3 className="section-subheading text-muted">
                <span>(We)</span> don't do a lot, <br />
                <span>(we)</span> do it in the right way.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="hidden-xs col-md-1" />
            <div className="col-xs-12 col-md-10">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Software advisory company specialized in two areas:
                  implementation of <strong>consumer-oriented products</strong>{' '}
                  & <strong>enterprise data processing solutions.</strong>
                </li>
                <li className="list-group-item">
                  Vast experience in working on projects from DACH countries.
                </li>
                <li className="list-group-item">
                  <strong>Continuous integration</strong> &{' '}
                  <strong>continuous delivery are</strong> part of our standard
                  way of working.
                </li>
                <li className="list-group-item">
                  Focused on delivering complete solutions.
                </li>
                <li className="list-group-item">
                  <strong>Transparency</strong> is a key value for us.
                </li>
                <li className="list-group-item">
                  We prefer serving solutions from{' '}
                  <strong>cloud environments</strong> but will not limit to
                  that.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
