import React from 'react';
import { FooterSection } from './section/FooterSection';
import { MVPFactorySection } from './section/MVPFactorySection';
import { ServicesSection } from './section/ServicesSection';
import { HeaderSection } from './section/HeaderSection';
import { ContactSection } from './section/ContactSection';
import { TopSection } from './section/TopSection';
import { HeadDefault } from './section/PGAHeadDefault';
import PGAPage from './PGAPage';
import PGANavbar from '../component/nav/PGANavbar';

export default class PGAHome extends PGAPage {
  render() {
    return (
      <div className="Application">
        <PGANavbar />
        <HeadDefault />
        <HeaderSection />
        <TopSection />
        <ServicesSection />
        <MVPFactorySection />
        <ContactSection />
        <FooterSection />
      </div>
    );
  }
}
