import React, { Component } from 'react';

export class ContactSection extends Component {
  render() {
    return (
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Contact Us</h2>
              <h3 className="section-subheading text-muted">
                <a
                  href="https://pga.atlassian.net/servicedesk/customer/portals"
                  title="Jira Service Management"
                >
                  <span className="fa fab-atlassian" />
                  Jira Service Management
                </a>
                <br />
                <br />
                <a
                  href="https://pga.atlassian.net/wiki/home"
                  title="Documentation Center"
                >
                  <span className="fa fab-confluence" />
                  Documentation Center
                </a>
                <br />
                <br />
                <a href="tel:+40-726-711-529">
                  <span className="fa fa-phone" />
                  +40-726-711-529
                </a>
                <br />
                <br />
                <a href="mailto:contact@pgaconsult.ro?Subject=Information%20required">
                  <span className="fa fa-envelope" /> contact@pgaconsult.ro
                </a>
                <br />
                <br />
                <a href="https://www.google.com/maps/place/Cluj-Napoca/">
                  <span className="fa fa-map-marker-alt" /> Gheorghe Dima 11/10,
                  <br /> Cluj-Napoca, Romania
                </a>
                <br />
                <br />
              </h3>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
